@mixin onsip-mat-icon($theme) {
  @include mat-icon-outlined;
  @include mat-icon-with-tooltip-cursor;
  @include mat-icon-question-mark-for-tooltip;
}

@mixin mat-icon-outlined() {
  .mat-icon[onsip-icon-outlined] {
    font-family: "Material Icons Outlined", sans-serif;

    // fallback
    src: url(../../fonts/fontFiles/Material-Icons-Outlined.woff2) format("woff2");
  }
}

@mixin mat-icon-with-tooltip-cursor() {
  .mat-icon[mattooltip] {
    cursor: default;
  }
}

@mixin mat-icon-question-mark-for-tooltip() {
  .mat-icon[onsip-question-mark-tooltip] {
    height: 14px;
    width: 14px;
    font-size: 14px;
  }
}
