@mixin onsip-mat-spinner($theme) {
  @include onsip-white-spinner;
}

@mixin onsip-white-spinner() {
  mat-spinner[onsip-white-spinner] {
    svg circle {
      stroke: white;
    }
  }
}
