@mixin onsip-mat-badge($theme) {
  @include onsip-badge-medium-font;
}

@mixin onsip-badge-medium-font() {
  .mat-badge[onsip-badge-medium-font] {
    .mat-badge-content {
      font-size: 12px;
    }
  }
}
