@mixin onsip-mat-divider($theme) {
  @include onsip-dotted-divider;
}

@mixin onsip-dotted-divider() {
  .mat-divider[onsip-dotted-divider] {
    border-image-source: url(../../../../../htdocs/resources/img/sayso/dotted-border.png);
    border-image-slice: 1 0;
    border-image-repeat: round;

    // fallback
    border-style: dashed;
    border-width: 1px 0 0;
  }
}
