@mixin onsip-mat-list($theme) {
  @include onsip-no-padding-list;
  @include onsip-wrap-lines-list;
}

@mixin onsip-no-padding-list() {
  mat-list,
  mat-action-list,
  mat-selection-list,
  mat-nav-list {
    &[onsip-no-padding-list] .mat-mdc-list-item {
      padding: 0 !important;
      width: 100%;
    }
  }
}

@mixin onsip-wrap-lines-list() {
  mat-list,
  mat-action-list,
  mat-selection-list,
  mat-nav-list {
    &[onsip-wrap-lines-list] {
      &.mat-mdc-list-base {
        .mat-mdc-list-item {
          height: unset;

          .mat-line {
            white-space: pre-line;
          }
        }
      }
    }
  }
}
