@mixin onsip-mat-radio-group($theme) {
  @include onsip-status-menu-radio-group;
  @include onsip-bold-selected-radio-button;
}

@mixin onsip-status-menu-radio-group() {
  .mat-mdc-radio-group[onsip-status-menu-radio-group] {
    .mat-mdc-radio-button .mdc-form-field {
      display: flex;
      justify-content: space-between;
    }

    // stylelint-disable selector-class-pattern
    .mdc-form-field--align-end > label {
      margin-left: 0;
      display: flex;
      align-items: center;
      width: 100%;
    }
    // stylelint-enable selector-class-pattern
  }
}

@mixin onsip-bold-selected-radio-button() {
  .mat-mdc-radio-group[onsip-bold-selected-radio-button] {
    .mat-mdc-radio-checked {
      .mdc-form-field > label {
        font-weight: 400;
      }
    }
  }
}
