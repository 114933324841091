@mixin onsip-mat-sidenav($theme) {
  @include onsip-sidenav-overflow;
}

@mixin onsip-sidenav-overflow() {
  .mat-sidenav[onsip-sidenav-overflow] {
    .mat-drawer-inner-container {
      overflow: visible;
    }
  }
}
