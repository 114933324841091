@mixin onsip-mat-form-field($theme) {
  @include onsip-form-field-fill;
  @include onsip-form-field-padding-fix;
  @include onsip-form-field-flush-outline;
  @include onsip-form-field-outline-white;
  @include onsip-form-field-white;
  @include onsip-form-field-white-background;
  @include onsip-form-field-same-height-suffix;
  @include onsip-hack-responsive-suffix;
  @include onsip-form-field-infix-auto;
  @include onsip-hide-subscript-wrapper;
}

@mixin onsip-form-field-fill {
  // NB: THIS IS ALL GLOBAL
  // stylelint-disable selector-class-pattern
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: white !important;
  }

  .mat-mdc-form-field-focus-overlay {
    background-color: white !important;
  }

  .mat-mdc-form-field-infix {
    min-height: unset !important;
  }

  .mat-mdc-form-field-hint {
    line-height: 1;
  }

  .mat-mdc-form-field-error {
    line-height: 1;
  }
}

@mixin onsip-form-field-padding-fix {
  .mat-mdc-form-field[onsip-form-field-padding-fix] {
    .mat-mdc-text-field-wrapper.mdc-text-field {
      padding: 0;
    }

    .mat-mdc-form-field-subscript-wrapper {
      .mat-mdc-form-field-hint-wrapper,
      .mat-mdc-form-field-error-wrapper {
        padding: 0;

        .mat-mdc-form-field-error,
        .mat-mdc-form-field-hint {
          line-height: initial;
        }
      }
    }
  }
}

/* Remove padding on inner containers, make inner part flush with outline
   useful for different formfield background than parent container */
@mixin onsip-form-field-flush-outline() {
  .mat-mdc-form-field[onsip-form-field-flush-outline] {
    border-radius: 8px;
  }
}

@mixin onsip-form-field-outline-white() {
  .mat-mdc-form-field[onsip-form-field-outline-white] {
    .mat-mdc-text-field-wrapper {
      background-color: white;
    }
  }
}

@mixin onsip-form-field-white() {
  .mat-mdc-form-field.onsip-form-field-white {
    color: white;

    .mat-mdc-input-element {
      caret-color: white;
    }

    .mat-mdc-text-field-wrapper {
      background-color: transparent !important;
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
    .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
      border-bottom-color: white;
    }

    .mdc-text-field--filled .mdc-line-ripple::after {
      border-bottom-color: white;
    }

    .mat-mdc-form-field-infix {
      min-height: unset !important;
    }

    .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
      color: white;
    }

    .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
      color: white;
    }

    // normal state
    .mat-form-field-label {
      color: white;
    }

    .mat-form-field-underline {
      background-color: white;
    }

    .mat-form-field-ripple {
      background-color: white;
    }

    // focused form state
    &.mat-focused {
      .mat-form-field-label {
        color: white;
      }

      .mat-form-field-underline {
        background-color: white;
      }

      .mat-form-field-ripple {
        background-color: white;
      }
    }

    // error form state
    &.mat-form-field-invalid {
      .mat-form-field-label {
        color: white;
      }

      .mat-form-field-underline {
        background-color: white;
      }

      .mat-form-field-ripple {
        background-color: white;
      }
    }
  }
}

@mixin onsip-form-field-white-background() {
  .mat-mdc-form-field.onsip-form-field-white-background
    > .mat-form-field-wrapper
    > .mat-form-field-flex
    > .mat-form-field-outline {
    background-color: white;
    border-radius: 8px;
  }
}

@mixin onsip-form-field-same-height-suffix() {
  .mat-mdc-form-field.onsip-form-field-same-height-suffix {
    .mat-mdc-form-field-icon-suffix {
      padding-right: 4px;
      display: flex;
      max-width: 50%;
      line-height: unset;
    }
  }
}

@mixin onsip-hack-responsive-suffix() {
  .mat-mdc-form-field.onsip-hack-responsive-suffix
    > .mat-form-field-wrapper
    > .mat-form-field-flex
    > .mat-form-field-infix {
    width: unset;
    padding-right: 4px; // little breathing room
  }

  .mat-mdc-form-field.onsip-hack-responsive-suffix
    > .mat-form-field-wrapper
    > .mat-form-field-flex
    > .mat-form-field-suffix {
    text-overflow: ellipsis;
    overflow: hidden;
    flex-shrink: 10;
  }
}

@mixin onsip-form-field-infix-auto() {
  .mat-mdc-form-field[onsip-form-field-infix-auto] {
    .mat-mdc-form-field-infix {
      width: auto;
    }
  }
}

@mixin onsip-hide-subscript-wrapper() {
  .mat-mdc-form-field[onsip-hide-subscript-wrapper] {
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }
}
