@import "../breakpoints";

@mixin onsip-mat-stepper($theme) {
  @include onsip-stepper-no-header;
  @include onsip-stepper-disabled-header;
  @include onsip-stepper-full-width;
}

@mixin onsip-stepper-no-header() {
  .onsip-stepper-no-header {
    .mat-horizontal-stepper-header-container {
      display: none !important;
    }

    .mat-horizontal-content-container {
      overflow: hidden; // hide any kind of scrolling from the stepper
      padding: 0;

      // padding: 0 12px 12px 12px;
    }
  }
}

@mixin onsip-stepper-full-width {
  .onsip-header-full-width {
    .mat-horizontal-stepper-header {
      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
}

@mixin onsip-stepper-disabled-header() {
  .onsip-stepper-disabled-header {
    .mat-horizontal-stepper-header-container {
      margin-bottom: 14px;
    }

    .mat-horizontal-stepper-header {
      pointer-events: none !important;

      &.cdk-keyboard-focused,
      &.cdk-program-focused,
      &:hover:not([aria-disabled]),
      &:hover[aria-disabled='false'] {
        background-color: transparent;
      }
    }

    .mat-step-label {
      min-width: 0;
    }

    .mat-horizontal-content-container {
      overflow: hidden;
      padding: 0;
    }

    .mat-step-text-label {
      @include less-than-or-eq-breakpoint(XSmall) {
        display: none;
      }
    }

    .mat-horizontal-stepper-header .mat-step-icon {
      @include less-than-or-eq-breakpoint(XSmall) {
        margin-right: 0;
      }
    }
  }
}
