@mixin onsip-mat-radio-button($theme) {
  @include dark-radio-button;
  @include onsip-mat-radio-button-label-align-normal;
}

@mixin dark-radio-button() {
  // stylelint-disable selector-class-pattern
  .mat-mdc-radio-button.mat-accent[onsipCustomColor="onsip-radio-dark"] .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
    border-color: #2b2d35 !important;
  }

  .mat-mdc-radio-button.mat-accent[onsipCustomColor="onsip-radio-dark"] .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
    border-color: #2b2d35 !important;
  }

  .mat-mdc-radio-button.mat-accent[onsipCustomColor="onsip-radio-dark"] .mat-ripple-element {
    background-color: #2b2d35 !important;
  }

  .mat-mdc-radio-button.mat-accent[onsipCustomColor="onsip-radio-dark"].mat-mdc-radio-checked .mdc-radio__background::before {
    background-color: #2b2d35 !important;
  }
}

@mixin onsip-mat-radio-button-label-align-normal() {
  .mat-mdc-radio-button[onsip-mat-radio-button-label-align-normal] {
    & > .mat-radio-label {
      align-items: normal;

      .mat-radio-label-content {
        white-space: normal;
      }
    }
  }
}
