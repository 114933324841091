@use "@angular/material" as mat;
@use "sass:map";
@import "../breakpoints";

@mixin onsip-mat-card($theme) {
  @include onsip-section-card($theme);
  @include onsip-list-card($theme);
}

// helper mixin
@mixin remove-mat-card-horizontal-padding($topBottomPadding) {
  padding: $topBottomPadding 0 !important;

  .mat-mdc-card-footer {
    margin-left: 0;
    margin-right: 0;
  }
}

@mixin onsip-section-card($theme) {
  .mat-mdc-card[onsip-section-card] {
    border-radius: 0;
    margin-bottom: 16px;

    @include remove-mat-card-horizontal-padding(16px);
    @include mat.elevation(0);

    .mat-mdc-card-header {
      padding: unset;
    }

    .mat-mdc-card-content {
      padding: unset;
    }

    & > .mat-mdc-card-title {
      border-bottom: 2px solid black;
      padding-bottom: 24px;
      margin-bottom: 0;
    }

    mat-card-header {
      border-bottom: 2px solid black;

      .mat-card-header-text {
        margin: 0;
      }

      mat-card-title {
        margin-bottom: 24px;
      }

      a {
        font-size: 16px;
        font-weight: 400;
        line-height: 36px;
        font-family: Roboto, sans-serif;
      }

      a:hover {
        text-decoration: underline;
      }
    }
  }
}

@mixin onsip-list-card($theme) {
  $foreground: map.get($theme, foreground);

  .mat-mdc-card[onsip-list-card] {
    border-radius: 0;
    border-top: 1px dashed mat.m2-get-color-from-palette($foreground, divider);

    @include remove-mat-card-horizontal-padding(24px);
    @include mat.elevation(0);

    &:last-of-type {
      border-bottom: 1px dashed mat.m2-get-color-from-palette($foreground, divider);
    }

    & > .mat-mdc-card-title {
      margin-bottom: 16px;
    }
  }
}
