@use '@angular/material' as mat;
@use "sass:map";

@mixin warn-text($mat-palette) {
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  small,
  strong,
  span {
    &.mat-warn {
      color: mat.m2-get-color-from-palette(map.get($mat-palette, warn));
    }
  }
}

@mixin palette-colors($mat-palette) {
  $palettes: primary accent warn;
  $colors: 50 100 200 300 400 500 600 700 800 900 A100 A200 A400 A700;

  @each $palette in $palettes {
    @each $color in $colors {
      .#{$palette}-#{$color}-bg {
        background: mat.m2-get-color-from-palette(map.get($mat-palette, $palette), $color) !important;
        color: mat.m2-get-color-from-palette(map.get($mat-palette, $palette), '#{$color}-contrast') !important;
      }

      .#{$palette}-#{$color} {
        color: mat.m2-get-color-from-palette(map.get($mat-palette, $palette), $color) !important;
      }

      .#{$palette}-#{$color}-border {
        border-color: mat.m2-get-color-from-palette(map.get($mat-palette, $palette), $color) !important;
      }
    }
  }
}

@mixin custom-color($name, $color, $contrast) {
  .onsip-#{$name}-background {
    background: #{$color} !important;
    color: #{$contrast} !important;
  }

  .onsip-#{$name}-color {
    color: #{$color} !important;
  }

  .onsip-#{name}-border {
    border-color: #{$color} !important;
  }
}
