@use "sass:map";
@import "./breakpoints";
@import "./app-grid-variables";

@mixin onsip-app-table-grid-mixins {
  @include onsip-grid-placement-shelf;
  @include onsip-grid-placement-table;
  @include onsip-grid-placement-shell-navigation;
  @include onsip-table-scroll;
}

@mixin onsip-grid-placement() {
  @each $breakpoint in $breakpoints {
    @include breakpoint($breakpoint) {
      $column-num: map.get($column-nums, $breakpoint);
      $column-width: map.get($column-widths, $breakpoint);
      $gutter: map.get($gutters, $breakpoint);

      grid-template-columns: repeat($column-num, $column-width);
      grid-column-gap: $gutter;

      // Shelves at XLarge should be the same as Large. The gutters are slightly different and so making them the same here
      @include breakpoint(XLarge) {
        grid-column-gap: map.get($gutters, Large);
      }

      grid-column: 1 / -1;
      display: inline-grid;
      grid-template-rows: auto;
      width: stretch;
      width: available;
    }
  }
}

@mixin onsip-grid-placement-shelf() {
  .onsip-grid-placement-shelf,
  div[onsip-grid-placement-shelf] {
    @include onsip-grid-placement;

  // stylelint-disable no-descending-specificity
    & > *:first-child {
      padding-left: 16px;
    }

    & > *:last-child {
      padding-right: 16px;
    }
  }
}

@mixin onsip-grid-placement-shell-navigation() {
  .onsip-grid-placement-shell-navigation,
  *[onsip-grid-placement-shell-navigation] {
    @include onsip-grid-placement;

    @include breakpoint(Small) {
      grid-template-columns: repeat(5, 1fr);
    }

    @include breakpoint(Medium) {
      grid-template-columns: repeat(8, 1fr);
    }

    @include greater-than-breakpoint(Medium) {
      grid-template-columns: repeat(9, 1fr);
    }

    & > *:first-child {
      @include breakpoint(XSmall) {
        padding-left: 16px;
      }
    }

    & > *:last-child {
      padding-right: 16px;

      @include breakpoint(XSmall) {
        padding-left: 16px;
      }
    }
  }
}

@mixin onsip-grid-placement-table {
  .onsip-grid-placement-table {
    tr {
      @include onsip-grid-placement;
    }
  }
}

/** provide map to fit table columns in the grid layout
  * map should be in this structure: (index: span_width, index+1: span_width)
  * index will always start with 1 and increment by 1
  * the value will be the width of column based on the grid. Total span width from all the columns
  * should not be greater than the max number of columns for that breakpoint
  */
@mixin place-table-columns-in-grid($map) {
  $current-column: 1;

  @each $index, $span in $map {
    mat-row > mat-cell:nth-child(#{$index}),
    mat-header-row > mat-header-cell:nth-child(#{$index}),
    mat-footer-row > mat-footer-cell:nth-child(#{$index}),
    div:nth-child(#{$index}),
    span:nth-child(#{$index}),
    th:nth-child(#{$index}),
    td:nth-child(#{$index}) {
      grid-column: $current-column / span $span;
      grid-row: 1;

      $current-column: $current-column + $span;
    }
  }
}

// specific to admin portal for now, the page should span the entire grid except at XLarge where it only spans to the 8th column
// this should be HostBinding to the "landing" pages in the admin portal
.onsip-admin-host-grid {
  & > * {
    grid-column: 1 / -1;

    @include breakpoint(XLarge) {
      grid-column: 1 / span 8;

      // design wants the grid to stop growing after 1920px. This is the width of the grid at 1920px.
      max-width: 968px;
    }
  }
}

@mixin onsip-table-scroll() {
  onsip-table-two[onsip-table-scroll] {
    overflow: hidden auto;
    height: 100%;
  }
}
