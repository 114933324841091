@mixin onsip-mat-snackbar($theme) {
  @include onsip-snackbar;
  @include onsip-snackbar-top;
}

@mixin onsip-snackbar() {
  .mat-mdc-snack-bar-container.onsip-snackbar {
    display: flex;
    min-width: 244px;

    // stylelint-disable selector-class-pattern
    .mdc-snackbar__surface {
      background-color: transparent !important;
    }

    .mdc-snackbar__label {
      color: black;
    }
    // stylelint-enable selector-class-pattern
  }
}

@mixin onsip-snackbar-top() {
  .mat-mdc-snack-bar-container.onsip-snackbar-top {
    position: relative;
    top: 48px;
  }
}
