@use '@angular/material' as mat;
@use "sass:map";

@mixin onsip-mat-tab-group($theme) {
  @include onsip-mat-tab-group-width($theme);
  @include onsip-tab-group-custom-width($theme);
  @include onsip-tab-group-primary($theme);
  @include onsip-no-chevron-tab-group($theme);
  @include onsip-tab-group-full-height($theme);
  @include onsip-mat-tab-table($theme);
}

// stylelint-disable selector-class-pattern
@mixin onsip-mat-tab-group-width($theme) {
  .mat-mdc-tab-group {
    .mat-mdc-tab {
      // NB: THIS IS GLOBAL
      min-width: 160px;
    }
  }
}

@mixin onsip-tab-group-custom-width($theme) {
  .mat-mdc-tab-group[onsip-tab-group-custom-width] {
    .mat-mdc-tab-header {
      border-bottom: none;
      margin: 0 auto;
      max-width: 320px;
    }

    .mat-mdc-tab-labels {
      border-radius: 8px;

      .mat-mdc-tab {
        min-width: 72px;
        width: 50%;

        .mdc-tab__content {
          .mat-badge-content {
            right: -26px;
            top: -16px;
            height: 16px;
            width: 16px;
            font-size: 12px;
            line-height: 16px;
          }
        }

        &:not(:focus) {
          opacity: 1;
        }
      }
    }
  }
}

@mixin onsip-tab-group-primary($theme) {
  $primary: mat.m2-get-color-from-palette(map.get($theme, primary), 500);

  .mat-mdc-tab-group[onsip-tab-group-primary] {
    .mat-mdc-tab-header {
      margin: 0 auto;
    }

    .mat-mdc-tab-labels {
      border: 2px solid $primary;
      color: $primary;

      .mat-mdc-tab {
        .mdc-tab__text-label {
          color: $primary;

          .mat-badge-content {
            color: white;
            background-color: $primary;
          }
        }
      }

      .mdc-tab--active {
        background-color: $primary;

        .mdc-tab__text-label {
          color: white;

          .mat-badge-content {
            color: $primary;
            background-color: white;
          }
        }
      }
    }

    .mdc-tab-indicator__content {
      display: none;
    }
  }
}

@mixin onsip-no-chevron-tab-group($theme) {
  .mat-mdc-tab-group[onsip-no-chevron-tab-group] {
    .mat-tab-header-pagination-chevron {
      display: none;
    }
  }
}

@mixin onsip-tab-group-full-height($theme) {
  .mat-mdc-tab-group.onsip-tab-group-full-height {
    height: 100%;

    .mat-tab-body-wrapper {
      height: 100%;
    }
  }
}

@mixin onsip-mat-tab-table($theme) {
  $primary: mat.m2-get-color-from-palette(map.get($theme, primary), 500);

  .mat-mdc-tab-group[onsip-mat-tab-table] {
    .mat-mdc-tab-header {
      border-bottom: 1px solid white;
    }

    .mdc-tab__text-label {
      text-transform: uppercase;
      font-size: 12px;
      color: rgba(0 0 0 / 60%);
    }

    .mdc-tab--active {
      color: $primary;
    }
  }
}
