@mixin onsip-mat-tooltip($theme) {
  @include onsip-multiline-tooltip;
  @include onsip-tooltip;
}

@mixin onsip-multiline-tooltip() {
  .mat-mdc-tooltip.onsip-multiline-tooltip {
    white-space: pre-line;
  }
}

@mixin onsip-tooltip {
  // NB: this is global
  // stylelint-disable selector-class-pattern
  .mdc-tooltip__surface {
    font-weight: 400 !important;
    max-width: 276px !important;
    font-size: 12px !important;
    line-height: 14px !important;
    padding: 12px !important;
    background-color: #d5f1eb !important;
    color: black !important;
  }
}
