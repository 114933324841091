@mixin onsip-mat-button-toggle-group($theme) {
  @include mat-button-toggle-group($theme);
}

@mixin mat-button-toggle-group($theme) {
  .mat-button-toggle-group {
    .mat-button-toggle {
      .mat-button-toggle-label-content {
        padding: 0 8px;
      }
    }
  }
}
