@use '@angular/material' as mat;
@import "../breakpoints";

@mixin onsip-mat-tab-nav-bar($theme) {
  @include remove-nav-cheron;
  @include remove-nav-pagination;
  @include onsip-shell-navigations;
}

@mixin remove-nav-cheron() {
  .mat-mdc-tab-nav-bar[onsip-no-chervon] {
    .mat-mdc-tab-header-pagination-chevron {
      display: none;
    }
  }
}

@mixin remove-nav-pagination() {
  .mat-mdc-tab-nav-bar[onsip-no-pagination] {
    .mat-mdc-tab-header-pagination {
      display: none;
    }
  }
}

@mixin onsip-shell-navigations() {
  .shell-navs[onsip-shell-navigations] {
    border-bottom: none;

    a {
      justify-content: start;
      text-align: start;
      word-break: break-word;
      font: 300 16px / 28px Roboto, sans-serif;
      color: black;
      opacity: 1;
    }

    // stylelint-disable selector-class-pattern
    .mdc-tab__text-label {
      color: black;
    }

    .active-nav {
      background: rgba(0 0 0 / 4%);

      a {
        font-weight: bold;
        opacity: 1;
        color: rgba(0 0 0 / 87%);
      }
    }

    .mdc-tab__ripple::before,
    .mat-ripple-element {
      background-color: black !important;
    }

    .left-ink-bar-container {
      position: absolute;
      left: 0;
      height: 48px;
      display: flex;
      align-items: center;
      transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);

      .left-ink-bar {
        height: 23px;
        width: 3px;
        background: mat.m2-get-color-from-palette($onsip-primary, 500);
      }
    }

    .mdc-tab--active {
      .mdc-tab__text-label {
        color: mat.m2-get-color-from-palette($onsip-primary, 500);
        opacity: 1;
        font-weight: 400;
      }
    }

    .mat-tab-link-container {
      padding: 6px 0;
    }

    .mat-tab-list {
      display: flex;
    }

    .mat-mdc-tab-links {
      flex-direction: column;
      width: 100%;
    }

    .mat-mdc-tab-link {
      padding: 0 32px;
    }

    .nested-navigation {
      .mat-mdc-tab-link {
        padding: 0 32px 0 48px;
      }
    }

    mat-ink-bar {
      display: none;
    }

    .mdc-tab-indicator {
      display: none;
    }
  }
}
