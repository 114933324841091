@use "@angular/material" as mat;
@use "sass:map";

@mixin onsip-mat-button($theme) {
  @include onsip-stroked-mat-fab($theme);
  @include onsip-icon-button($theme);
  @include onsip-colored-stroked-button($theme);
  @include onsip-sayso-fab;
  @include onsip-mini-fab-no-shadow;
  @include onsip-status-menu-e911-button;
}

@mixin onsip-colored-stroked-button($theme) {
  // NB: THIS IS GLOBAL
  .mat-mdc-outlined-button {
    &:not([disabled]) {
      border-color: currentColor !important;
    }
  }
}

@mixin onsip-icon-button($theme) {
  .mdc-button.mat-mdc-unelevated-button,
  .mdc-button.mat-mdc-raised-button,
  .mdc-button.mat-mdc-outlined-button {
    display: flex !important;
  }

  // NB: THIS IS GLOBAL
  .mat-mdc-unelevated-button > .mat-icon.material-icons,
  .mat-mdc-raised-button > .mat-icon.material-icons,
  .mat-mdc-outlined-button > .mat-icon.material-icons {
    font-size: 24px;
    height: 24px;
    width: 24px;
    margin-right: 0;
    margin-left: 0;
  }
}

@mixin onsip-stroked-mat-fab($theme) {
  $primary: mat.m2-get-color-from-palette(map.get($theme, primary), 500);
  $disabled: map.get(map.get($theme, foreground), disabled-button);

  // NB: THIS IS GLOBAL
  .mat-mdc-fab {
    box-shadow: none !important;
  }

  .mat-mdc-fab[onsip-stroked-mat-fab] {
    border: 1px solid;

    &[disabled] {
      border-color: $disabled !important;
      background-color: white !important;

      mat-icon {
        color: $disabled !important;
      }
    }

    &[color="primary"] {
      background-color: white; // this has to overrule [color="primary"] from mat'l
      border-color: $primary;
      
      mat-icon {
        color: $primary;
      }
    }

    // accent and warn unused, make them if we end up needing
  }
}

@mixin onsip-sayso-fab() {
  button[mat-fab][onsip-sayso-fab] {
    width: 44px;
    height: 44px;

    .mat-button-wrapper {
      padding: 0;
    }
  }
}

@mixin onsip-mini-fab-no-shadow() {
  button[mat-mini-fab][onsip-mini-fab-no-shadow] {
    box-shadow: none;
  }
}

@mixin onsip-status-menu-e911-button {
  button[onsip-status-menu-e911-button] {
    // stylelint-disable selector-class-pattern
    .mdc-list-item__primary-text,
    .mat-mdc-menu-item-text {
      width: 100%;
    }
  }
}
