@mixin onsip-mat-checkbox($theme) {
  @include onsip-checkbox-thin-border;
  @include onsip-bold-selected-checkbox;
}

@mixin onsip-checkbox-thin-border() {
  .mat-mdc-checkbox[onsip-checkbox-thin-border] {
    // stylelint-disable selector-class-pattern
    .mdc-checkbox__background {
      border-width: thin;
    }

    .mdc-form-field {
      height: 20px;
    }
  }
}

@mixin onsip-bold-selected-checkbox() {
  .mat-mdc-checkbox[onsip-bold-selected-checkbox] {
    &.mat-mdc-checkbox-checked {
      .mdc-form-field > label {
        font-weight: 400;
      }
    }
  }
}
