@use "sass:map";
@import "./breakpoints";
@import "./app-grid-variables";

// WARNING DO NOT IMPORT THIS FILE EXCEPT IN app-theme.scss

@mixin breakpoint-grid-layout($breakpoint) {
  $column-num: map.get($column-nums, $breakpoint);
  $column-width: map.get($column-widths, $breakpoint);
  $gutter: map.get($gutters, $breakpoint);
  $margin-top: map.get($margin-tops, $breakpoint);
  $margin-right: map.get($margin-rights, $breakpoint);
  $margin-bottom: map.get($margin-bottoms, $breakpoint);
  $margin-left: map.get($margin-lefts, $breakpoint);

  @include breakpoint($breakpoint) {
    .onsip-grid-content {
      @if $breakpoint == XSmall or $breakpoint == Small {
        grid-template-columns: repeat($column-num, $column-width);
      } @else {
        grid-template-columns: repeat($column-num, $column-width);
      }

      margin: $margin-top $margin-right $margin-bottom $margin-left;
      grid-column-gap: $gutter;
      grid-column: 1 / -1; // always a child of .onsip-grid-full
      display: inline-grid !important;
      grid-template-rows: auto;
      width: stretch;
      width: available;
    }

    .onsip-grid-placement {
      grid-template-columns: repeat($column-num, $column-width);
      grid-column-gap: $gutter;

      // Shelves at XLarge should be the same as Large. The gutters are slightly different and so making them the same here
      @include breakpoint(XLarge) {
        grid-column-gap: map.get($gutters, Large);
      }

      grid-column: 1 / -1;
      display: inline-grid;
      grid-template-rows: auto;
      width: stretch;
      width: available;
    }
  }
}

// use the margins from the grid, but don't actually make the container display: grid
@mixin breakpoint-no-grid($breakpoint) {
  $margin-top: map.get($margin-tops, $breakpoint);
  $margin-right: map.get($margin-rights, $breakpoint);
  $margin-bottom: map.get($margin-bottoms, $breakpoint);
  $margin-left: map.get($margin-lefts, $breakpoint);

  .onsip-no-grid {
    margin: $margin-top $margin-right $margin-bottom $margin-left;
    display: block;
    width: stretch;
    width: available;
  }
}

@each $breakpoint in $breakpoints {
  @include breakpoint-grid-layout($breakpoint);
  @include breakpoint-no-grid($breakpoint);
}
