// CAUTION!
// - If you are importing this file you are probably doing the wrong thing.
// - If you are looking to use color/palette/theme function or variables import ./app-theme-variables.scss
// - The CSS generated from this file should be included only once per application!

@use '@angular/material' as mat;
@use "sass:map";

// Our custom material styles
@use "./app-theme-mixins" as onsip;

// Our palettes and themes
@import "./app-theme-variables";

// include the grid
@import "./app-grid";

// mixins
@import "./mat-components/mat-divider";
@import "./mat-components/mat-radio-group";
@import "./mat-components/mat-radio-button";
@import "./mat-components/mat-badge";
@import "./mat-components/mat-button";
@import "./mat-components/mat-icon";
@import "./mat-components/mat-form-field";
@import "./mat-components/mat-tab-group";
@import "./mat-components/mat-card";
@import "./mat-components/mat-checkbox";
@import "./mat-components/mat-snackbar";
@import "./mat-components/mat-spinner";
@import "./mat-components/mat-paginator";
@import "./mat-components/mat-tab-nav-bar";
@import "./mat-components/mat-list";
@import "./mat-components/mat-menu";
@import "./mat-components/mat-dialog";
@import "./mat-components/mat-tooltip";
@import "./mat-components/mat-sidenav";
@import "./mat-components/mat-stepper";
@import "./mat-components/mat-button-toggle-group";
@import "./app-table-grid";
@include onsip.warn-text($onsip-theme);

// Generate classes for palettes
@include onsip.palette-colors($onsip-theme);

// OnSIP Custom Colors

// These are colors that we are using for specific purposes.
// Any uses of off-palette colors should be setup and maintaied here.

// Calling
@include onsip.custom-color(call-begin, #0FA189, #ffffff);
@include onsip.custom-color(call-end, #EA0E40, #ffffff);

// Snackbar
@include onsip.custom-color(snackbar-success, #C2E7E1, #000000);
@include onsip.custom-color(snackbar-error, #F7D0D5, #000000);

// Tooltip
@include onsip.custom-color(tooltip, #D5F1EB, #000000);

// General Settings
@include onsip.custom-color(settings-card, #f6f6f7, #000000);

// Profile
@include onsip.custom-color(profile-card, #2b2d35, #ffffff);

// Sayso Video Settings
@include onsip.custom-color(video-enabled, #0fa189, #ffffff);
@include onsip.custom-color(video-disabled, #ff6d08, #ffffff);

// Fake mat-form-field outline border
// for use with components that require the same styling as a mat form field but are not form controls
.onsip-form-field-outline-border {
  border: 1px solid rgba(0 0 0 / 12%);
  border-radius: 5px;
}

@include onsip-mat-divider($onsip-theme);
@include onsip-mat-radio-button($onsip-theme);
@include onsip-mat-radio-group($onsip-theme);
@include onsip-mat-badge($onsip-theme);
@include onsip-mat-button($onsip-theme);
@include onsip-mat-icon($onsip-theme);
@include onsip-mat-form-field($onsip-theme);
@include onsip-mat-tab-group($onsip-theme);
@include onsip-mat-card($onsip-theme);
@include onsip-mat-checkbox($onsip-theme);
@include onsip-mat-snackbar($onsip-theme);
@include onsip-mat-spinner($onsip-theme);
@include onsip-mat-paginator($onsip-theme);
@include onsip-mat-tab-nav-bar($onsip-theme);
@include onsip-mat-list($onsip-theme);
@include onsip-mat-menu($onsip-theme);
@include onsip-mat-dialog($onsip-theme);
@include onsip-mat-tooltip($onsip-theme);
@include onsip-mat-sidenav($onsip-theme);
@include onsip-mat-stepper($onsip-theme);
@include onsip-mat-button-toggle-group($onsip-theme);
@include onsip-app-table-grid-mixins;

// Material level fixes

.mat-app-background {
  background-color: white !important;
}

.mat-toolbar {
  background-color: white !important;
}

.mat-drawer-container {
  background-color: white !important;
}

// OnSIP typography levels
.onsip-page-header {
  line-height: 40px;
  font-size: 32px;
  font-weight: 300;
}

.onsip-start-conference-link {
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  color: mat.m2-get-color-from-palette(map.get($onsip-theme, primary), 500);

  &:hover {
    color: mat.m2-get-color-from-palette(map.get($onsip-theme, primary), 700);
  }

  &:active {
    color: mat.m2-get-color-from-palette(map.get($onsip-theme, primary), 900);
  }
}

.onsip-table-column-header {
  line-height: 16px;
  font-size: 12px;
  font-weight: 400;
}

.onsip-contact-list-timer {
  line-height: 14px;
  font-size: 12px;
  font-weight: 300;
}

// Signup/Onboarding typography: here for now before we make new files
.onsip-signup-title {
  font-size: 32px;
  line-height: normal;
  font-weight: 300;

  @media (min-width: 1920px) {
    font-size: 44px;
    line-height: normal;
    font-weight: 300;
  }
}

.onsip-signup-headline {
  font-size: 20px;
  line-height: 36px;
  font-weight: 300;

  @media (min-width: 1920px) {
    font-size: 24px;
    line-height: 36px;
    font-weight: 300;
  }
}

// Representation of a collection of typography levels.
// The defaults are based on https://material.io/guidelines/style/typography.html
// and match therein (more or less). However the naming doesn't match at all with
// what Angular Material library has implemented which is a point for confusion.
// So here is the mapping of material.io names to variable names for reference...

// Material H1 => $display-4
// Material H2 => $display-3
// Material H3 => $display-2
// Material H4 => $display-1
// Material H5 => $headline
// Material H6 => $title
// Material Subtitle 1 => $subheading-2
// Material Subtitle 2 => $subheading-1
// Material Body 1 => $body-2 (bolder body text)
// Material Body 2 => $body-1 (base body text)
// Material Caption => $caption (smaller body text)

// Material Light => 300
// Material Regular => 400
// Material Medium => 500

// Our typography levels packaged as a mat-typography-config
// If you want to make a custom typography implementation you will hardcode it yourself
$onsip-mat-typography: mat.m2-define-typography-config(
  $font-family:   'Roboto, sans-serif',
  $headline-1:     mat.m2-define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
  $headline-2:     mat.m2-define-typography-level(56px, 56px, 300, $letter-spacing: -0.02em),
  $headline-3:     mat.m2-define-typography-level(45px, 48px, 300, $letter-spacing: -0.005em),
  $headline-4:     mat.m2-define-typography-level(34px, 40px, 300),
  $headline-5:      mat.m2-define-typography-level(24px, 32px, 300),
  $headline-6:         mat.m2-define-typography-level(20px, 32px, 300),
  $subtitle-1:  mat.m2-define-typography-level(16px, 28px, 300),
  $subtitle-2:  mat.m2-define-typography-level(15px, 24px, 300),
  $body-1:        mat.m2-define-typography-level(14px, 20px, 400),
  $body-2:        mat.m2-define-typography-level(14px, 20px, 300),
  $caption:       mat.m2-define-typography-level(12px, 20px, 300),
  $button:        mat.m2-define-typography-level(14px, 14px, 400)
);

@include mat.all-component-typographies($onsip-mat-typography);
@include mat.core;

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component that you are using.

@include mat.all-component-themes($onsip-theme);

// Include the alternative theme styles inside of a block with a CSS class. You can make this
// CSS class whatever you want. In this case, any component inside of an element with
// `.onsip-dark-theme` will be affected by this alternate dark theme instead of the default theme.
.onsip-dark-theme {
  @include mat.all-component-colors($onsip-dark-theme);
}

// reset the body
body {
  margin: 0;

  // add font smoothing to the whole app
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
