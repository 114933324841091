@use "sass:list";

// DEVELOPER: the public part of the file is at the bottom

/*
The angular material default breakpoints as a scss map
source:
https://github.com/angular/components/blob/master/src/cdk/layout/breakpoints.ts
*/
$breakpoint-queries: (
  XSmall: '(max-width: 599.98px)',
  Small: '(min-width: 600px) and (max-width: 959.98px)',
  Medium: '(min-width: 960px) and (max-width: 1279.98px)',
  Large: '(min-width: 1280px) and (max-width: 1919.98px)',
  XLarge: '(min-width: 1920px)',
  Handset: '(max-width: 599.98px) and (orientation: portrait), ' + '(max-width: 959.98px) and (orientation: landscape)',
  Tablet: '(min-width: 600px) and (max-width: 839.98px) and (orientation: portrait), ' + '(min-width: 960px) and (max-width: 1279.98px) and (orientation: landscape)',
  Web: '(min-width: 840px) and (orientation: portrait), ' + '(min-width: 1280px) and (orientation: landscape)',
  HandsetPortrait: '(max-width: 599.98px) and (orientation: portrait)',
  TabletPortrait: '(min-width: 600px) and (max-width: 839.98px) and (orientation: portrait)',
  WebPortrait: '(min-width: 840px) and (orientation: portrait)',
  HandsetLandscape: '(max-width: 959.98px) and (orientation: landscape)',
  TabletLandscape: '(min-width: 960px) and (max-width: 1279.98px) and (orientation: landscape)',
  WebLandscape: '(min-width: 1280px) and (orientation: landscape)',
);
$upper-bounds: (
  XSmall: 599.98px,
  Small: 959.98px,
  Medium: 1279.98px,
  Large: 1919.98px,
  HandsetPortrait: 599.98px,
  TabletPortrait: 839.98px,
  HandsetLandscape: 959.98px,
  TabletLandscape: 1279.98px
);
$lower-bounds: (
  Small: 600px,
  Medium: 960px,
  Large: 1280px,
  XLarge: 1920px,
  TabletPortrait: 600px,
  WebPortrait: 840px,
  TabletLandscape: 960px,
  WebLandscape: 1280px
);
$base-sizes: XSmall Small Medium Large XLarge;
$device-portraits: HandsetPortrait TabletPortrait WebPortrait;
$device-landscapes: HandsetLandscape TabletLandscape WebLandscape;
$device-composites: Handset Tablet Web;
$breakpoint-categories: $base-sizes $device-portraits $device-landscapes $device-composites;

@function contains($list, $item) { @return index($list, $item) != null; }

@function get-next-breakpoint($breakpoint) {
  @each $category in $breakpoint-categories {
    @if contains($category, $breakpoint) {
      $found: list.index($category, $breakpoint);

      @if $found == length($category) - 1 {
        @error "`#{$breakpoint}` is the biggest breakpoint available for that category";
      } @else {
        @return nth($category, $found + 1);
      }
    }
  }

  @error "Breakpoint `#{$breakpoint}` doesn't exist, check your casing against the maps in breakpoints.scss";
}

@function get-previous-breakpoint($breakpoint) {
  @each $category in $breakpoint-categories {
    @if contains($category, $breakpoint) {
      $found: list.index($category, $breakpoint);

      @if $found == 0 {
        @error "`#{$breakpoint}` is the smallest breakpoint available for that category";
      } @else {
        @return nth($category, $found - 1);
      }
    }
  }

  @error "Breakpoint `#{$breakpoint}` doesn't exist, check your casing against the maps in breakpoints.scss";
}

@function greater-than-breakpoint-query($breakpoint) {
  @if map-has-key($lower-bounds, get-next-breakpoint($breakpoint)) {
    @return '(min-width: #{map-get($lower-bounds, get-next-breakpoint($breakpoint))})';
  } @else {
    @error "Breakpoint: `#{$breakpoint}` doesn't exist or doesn't have a lower bound, check your casing against the maps in breakpoints.scss";
  }
}

@function less-than-or-eq-breakpoint-query($breakpoint) {
  @if map-has-key($upper-bounds, $breakpoint) {
    @return '(max-width: #{map-get($upper-bounds, $breakpoint)})';
  } @else {
    @error "Breakpoint: `#{$breakpoint}` doesn't exist or doesn't have an upper bound, check your casing against the maps in breakpoints.scss";
  }
}

// PUBLIC API: use these in your component-level scss files:

@mixin breakpoint($breakpoint) {
  @if map-has-key($breakpoint-queries, $breakpoint) {
    @media #{map-get($breakpoint-queries, $breakpoint)} { @content; }
  } @else {
    @error "Breakpoint: `#{$breakpoint}` doesn't exist, check your casing against the maps in breakpoints.scss";
  }
}

@mixin less-than-or-eq-breakpoint($breakpoint) {
  $query: less-than-or-eq-breakpoint-query($breakpoint);

  @if contains($device-portraits, $breakpoint) {
    $query: '#{$query} and (orientation: portrait)';
  } @else if contains($device-landscapes, $breakpoint) {
    $query: '#{$query} and (orientation: landscape)';
  }

  @media #{$query} { @content; }
}

@mixin greater-than-breakpoint($breakpoint) {
  $query: greater-than-breakpoint-query($breakpoint);

  @if contains($device-portraits, $breakpoint) {
    $query: '#{$query} and (orientation: portrait)';
  } @else if contains($device-landscapes, $breakpoint) {
    $query: '#{$query} and (orientation: landscape)';
  }

  @media #{$query} { @content; }
}
