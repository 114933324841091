@use '@angular/material' as mat;
@use "sass:map";

@mixin onsip-mat-paginator($theme) {
  @include primary-mat-paginator-arrow($theme);
  @include onsip-mat-paginator-fix-margin($theme);
  @include onsip-mat-paginator-shelf-navigation($theme);
}

@mixin primary-mat-paginator-arrow($theme) {
  $primary: map.get($theme, primary);

  mat-paginator[onsip-paginator-primary-icon] {
    .mat-mdc-icon-button {
      color: mat.m2-get-color-from-palette($primary);
    }

    .mat-mdc-paginator-icon {
      fill: mat.m2-get-color-from-palette($primary);
    }
  }
}

@mixin onsip-mat-paginator-fix-margin($theme) {
  // NOTE: THIS IS GLOBAL
  .mat-mdc-paginator {
    .mat-mdc-paginator-range-label {
      margin: 0 32px 0 0;
    }
  }
}

@mixin onsip-mat-paginator-shelf-navigation($theme) {
  mat-paginator[onsip-mat-paginator-shelf-navigation] {
    .mat-mdc-paginator-range-label {
      margin: 0 8px 0 0;
    }

    .mat-mdc-paginator-container {
      padding: 0;
    }
  }
}
