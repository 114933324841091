@import "../breakpoints";

@mixin onsip-mat-dialog($theme) {
  @include onsip-dialog-width;
  @include onsip-dialog-outdated-sayso-settings;
  @include onsip-dialog-rounded;
  @include onsip-dialog-right-correction;
  @include onsip-dialog-transparent-backdrop;
  @include onsip-dialog-universal-style;
  @include onsip-dialog-l-size;
  @include onsip-dialog-margin;
}

$max-width: 75vw;

@mixin onsip-dialog-universal-style() {
  .onsip-dialog-universal-style {
    @include less-than-or-eq-breakpoint(XSmall) {
      max-width: 90vw !important;
    }

    .mat-mdc-dialog-surface {
      max-width: 492px !important;
      min-width: 280px !important;
      padding: 20px 16px 16px;
      border-radius: 8px !important;

      .mat-mdc-dialog-title {
        margin-bottom: 24px;
        padding: 0;
      }

      // stylelint-disable selector-class-pattern
      .mdc-dialog__title::before {
        display: none;
      }
      // stylelint-enable selector-class-pattern

      .mat-mdc-dialog-actions {
        padding: 0;
        margin-bottom: 0;
        min-height: 0;
      }

      .mat-mdc-dialog-content {
        margin: 0 0 16px;
        padding: 0;
        width: 100%;
      }

      .mat-mdc-button {
        min-width: 100px;
      }
    }
  }
}

@mixin onsip-dialog-rounded-helper() {
  .mat-mdc-dialog-surface {
    border-radius: 20px !important;
  }
}

@mixin onsip-dialog-rounded() {
  .onsip-dialog-rounded {
    @include onsip-dialog-rounded-helper;
  }
}

@mixin onsip-dialog-width() {
  .onsip-dialog-width {
    @include onsip-dialog-rounded-helper;

    .mat-mdc-dialog-surface {
      max-width: $max-width;
    }
  }
}

@mixin onsip-dialog-outdated-sayso-settings() {
  // styling for mat-dialog in this case only covers the mat-sidenav-content portion
  .onsip-dialog-outdated-sayso-settings {
    // copy from mat-dialog mat-dialog cdk-overlay-dark-backdrop
    background: rgba(0 0 0 / 32%);
    opacity: 1;
    margin-top: 64px; // toolbar height
    margin-left: 300px; // contact width

    @include less-than-or-eq-breakpoint(Small) {
      margin-left: 0;
    }

    @include breakpoint(XSmall) {
      margin-top: 56px; // XSmall toolbar height
    }
  }
}

@mixin onsip-dialog-right-correction() {
  .onsip-dialog-right-correction {
    @include onsip-dialog-rounded-helper;

    .mat-mdc-dialog-surface {
      padding: 0;
      margin-right: 24px;
    }
  }
}

@mixin onsip-dialog-transparent-backdrop() {
  .onsip-dialog-transparent-backdrop {
    opacity: 1;
  }
}

@mixin onsip-dialog-l-size() {
  .onsip-dialog-l-size {
    .mat-mdc-dialog-surface {
      max-width: 760px;
    }
  }
}

@mixin onsip-dialog-margin() {
  .onsip-dialog-margin {
    margin-left: 280px;

    @include less-than-or-eq-breakpoint(XSmall) {
      margin: 0 auto;
    }
  }
}
