@mixin onsip-mat-menu($theme) {
  @include onsip-menu-status-menu;
  @include onsip-mat-menu-overflow-visible;
}

@mixin onsip-menu-status-menu() {
  .onsip-menu-status-menu {
    &.mat-mdc-menu-panel {
      border-radius: 8px;
      max-width: unset !important;
      width: 300px;

      .mat-mdc-menu-content {
        padding: 0;
      }
    }
  }
}

@mixin onsip-mat-menu-overflow-visible() {
  .mat-mdc-menu-panel.onsip-mat-menu-overflow-visible {
    overflow: visible !important;
  }
}
